import {Message} from 'element-ui';

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home.vue'

import Frontend from '@/views/frontend/layout'

import Login from '@/views/frontend/login'
import Recharge from '@/views/frontend/recharge'
import RechargeList from '@/views/frontend/recharge_list'
import FrontendTransactions from '@/views/frontend/transactions'
import Withdrawal from '@/views/frontend/withdrawal'
import AlipayReturn from '@/views/frontend/pay_return/alipay'
import ChangePassword from '@/views/frontend/change_password'
import FrontendWithdraws from '@/views/frontend/withdraws'

import Layout from '@/views/admin/layout'
import Dashboard from '@/views/admin/dashboard'
import Users from '@/views/admin/users/users'
import UsersInfo from '@/views/admin/users/user_info'
import Transactions from '@/views/admin/transactions'
import Recharges from '@/views/admin/recharges'
import Withdraws from '@/views/admin/withdraws/withdraws'
import WithdrawsInfo from '@/views/admin/withdraws/withdraws_info'

import Orders from '@/views/admin/orders/index'
import OrdersCreate from '@/views/admin/orders/create'
import OrdersCreateBatch from '@/views/admin/orders/create_batch'
import OrdersInfo from '@/views/admin/orders/info'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'home', component: Home },
  { path: '/login', name: 'login', component: Login, meta: { tag: '登录' } },

  {
    path: '/frontend', name: 'frontend', component: Frontend,  children: [
      { path: '/recharge', name: 'recharge', component: Recharge, meta: { tag: '充值' } },
      { path: '/recharge_list', name: 'recharge_list', component: RechargeList, meta: { tag: '充值记录' } },
      { path: '/transactions', name: 'frontend_transactions', component: FrontendTransactions, meta: { tag: '链上数据' } },
      { path: '/withdrawal', name: 'withdrawal', component: Withdrawal, meta: { tag: '提现' } },
      { path: '/alipay/return', name: 'alipayReturn', component: AlipayReturn, meta: { tag: '支付宝充值' } },
      { path: '/change_password', name: 'change_password', component: ChangePassword, meta: { tag: '修改密码' } },
      { path: '/withdraws', name: 'frontend_withdraws', component: FrontendWithdraws, meta: { tag: '提现记录' } },
    ]
  },

  {
    path: '/admin', name: 'admin', component: Layout, children: [
      { path: '/admin', name: 'dashboard', component: Dashboard, meta: { tag: '' } },
      { path: 'users', name: 'users', component: Users, meta: { tag: '用户列表' } },
      { path: 'users/:id(\\d+)/info', name: 'users.info', component: UsersInfo, meta: { tag: '用户详情' } },
      { path: 'transactions', name: 'transactions', component: Transactions, meta: { tag: '交易数据' } },
      { path: 'recharges', name: 'recharges', component: Recharges, meta: { tag: '充值记录' } },
      { path: 'withdraws', name: 'withdraws', component: Withdraws, meta: { tag: '提现列表' } },
      { path: 'withdraws/:id(\\d+)/info', name: 'withdraws.info', component: WithdrawsInfo, meta: { tag: '提现详情' } },
      { path: 'orders', name: 'orders', component: Orders, meta: { tag: '预付单列表' } },
      { path: 'orders/create', name: 'orders.create', component: OrdersCreate, meta: { tag: '创建惩罚预付单' } },
      { path: 'orders/create_batch', name: 'orders.create.batch', component: OrdersCreateBatch, meta: { tag: '创建奖励预付单' } },
      { path: 'orders/:id(\\d+)/info', name: 'orders.info', component: OrdersInfo, meta: { tag: '预付单详情' } },
    ]
  },


  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/frontend/About.vue')
  // }
]


const router = new VueRouter({
  mode: process.env.VUE_APP_ROUTER_MODE,
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const profile = JSON.parse(localStorage.getItem('profile'));
  const isAdmin = profile ? profile.is_admin : false; // 是否有后台访问权限

  if (!profile && to.name != 'login' && from.name != 'login') {
    next({ replace: true, name: 'login' });
    return;
  }

  document.getElementsByTagName('body')[0].className = 'frontend-body'

  if (to.matched.some(e => e.path == '/admin')) {
    document.getElementsByTagName('body')[0].className = 'admin-body'
  }

  if (!isAdmin && to.matched.some(e => e.path == '/admin')) {
    next(from.path);
    Vue.swal.fire({
      icon: 'error',
      title: '没有后台访问权限',
      text: '您没有后台访问权限，如需查看更多数据，请联系大佬',
    });
  } else {
    next();
  }
})

export default router
