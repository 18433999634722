<template>
  <div class="d-flex justify-content-center vh-100" style="margin-top: -60px;">
    <div class="page-login my-auto">
      <div class="row justify-content-center align-items-center">
        <div class="login-info">
          <div class="card df-card shadow-lg">
            <div class="card-body">
<!--               <div class="login-bg">
                <img src="@/assets/images/gdt-login-bg.png" class="img-fluid">
              </div> -->
              <h5 class="card-title">登录</h5>
              <el-form :model="form"  status-icon :rules="rules" ref="form" @keyup.enter.native="submitForm('form')">
                <el-form-item label="" prop="telephone">
                  <el-input v-model="form.telephone" placeholder="手机号"></el-input>
                </el-form-item>
                <el-form-item label="" prop="password">
                  <el-input type="password" v-model="form.password" placeholder="密码"></el-input>
                </el-form-item>
                <el-form-item class="submit-btn">
                  <el-button type="primary" @click="submitForm('form')">提交</el-button>
                </el-form-item>
                <div class="form-bottom">
                  <router-link to="/" class="btn btn-link btn-right"></router-link>
                  <router-link to="/9527" class="btn btn-link btn-right" @click.native="toRegister">注册</router-link>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        telephone: '',
        password: '',
      },
      rules: {
        telephone: [
          { required: true, message: '手机号不能为空', trigger: 'change' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'change' }
        ],
      }
    }
  },

  mounted() {
  },

  methods: {
    toRegister() {
      this.$swal.fire({
        icon: 'info',
        title: '提示',
        text: '注册功能暂时未开放',
      })
    },

    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.$swal.fire({
            title: '登录中...',
            text: '请耐心等待！',
            didOpen: () => {
              this.$swal.showLoading()

              this.$http.put('auth/login', this.form, {hmsg: true}).then(res => {
                localStorage.setItem('Authorization', res.token)
                this.$router.push('/');
                this.$swal.close(); // 关闭弹窗
              }).catch(res => {
                this.$swal.hideLoading()
                this.$swal.fire({
                  icon: 'error',
                  title: res.data.message,
                })
              })
            }
          })
        }
      });
    }
  },
}
</script>